import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ad3e3f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pc-nui-classic-bet-box-root" }
const _hoisted_2 = ["data-chip"]
const _hoisted_3 = {
  key: 0,
  class: "bet-info-wrap"
}
const _hoisted_4 = { class: "bet-amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_dolar = _resolveComponent("svg-dolar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "pc-nui-classic-bet-box-container",
      style: _normalizeStyle(_ctx.chipWrapStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chipsUI, (chip, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "chip chip_bet_box_ui",
          key: index,
          style: _normalizeStyle(_ctx.chipStyle(chip.x, chip.y)),
          "data-chip": chip.class
        }, null, 12, _hoisted_2))
      }), 128))
    ], 4),
    (_ctx.amount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_svg_dolar, { class: "svg" }),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatNumberCurency(_ctx.amount)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}