
import { Nui } from '@/types/Nui'
import { PropType, TableData, defineComponent, watch } from 'vue'

import { useNiuCardResult } from '@/composables/useNiuCardResult'

export default defineComponent({
    emits: ['on-game-result'],
    props: {
        data: {
            type: Object as PropType<TableData>,
            required: false
        }
    },
    setup(props) {
        const {
            bankerCards,
            isShown,
            player1Cards,
            player2Cards,
            player3Cards,
            winResult,
            bankerFlash,
            player1Flash,
            player2Flash,
            player3Flash,
            parseResult
        } = useNiuCardResult()

        watch(
            () => props.data,
            (v) => {
                if (v && !isShown) {
                    parseResult(v)
                }
            },
            { immediate: true }
        )

        return {
            bankerCards,
            player1Cards,
            player2Cards,
            player3Cards,
            winResult,
            bankerFlash,
            player1Flash,
            player2Flash,
            player3Flash
        }
    }
})
