
import { getPrintDetailsFromApi, getTableDetail, printSuccess } from '@/api'
import { RoadMapData } from '@/components/roadmap/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/roadmap/utils'
import { ROUTES } from '@/router'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import {
    EventBuss,
    GameCurrencies,
    NiuBonusType,
    SOCKET_MESSAGE
} from '@/types/global'
import {
    bonusTypeToStringValue,
    formatWithCommas,
    generateRandomNumber,
    getDeviceOrientation,
    langToNoticeLanguageValue
} from '@/utils'
import {
    BonusInfo,
    Emitter,
    LimitOption,
    Notice,
    PropType,
    ResponseData,
    ResultMemberList,
    SocketMessage,
    TableData,
    TableDetails,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
    createApp,
    h,
    BonusContent
} from 'vue'
import { useRouter } from 'vue-router'
import { VTextMarquee } from 'vue-text-marquee'
import {
    IconNotice,
    IconUserInfo,
    SvgDolar,
    SvgFullScreenToggle,
    SvgSetting,
    IconAllRoad,
    IconBigRoad,
    IconThumbsUp4,
    IconStatistics,
    iconQuestion,
    SvgPwdToggle
} from '@/components/icons'
import { Nui, NuiData } from '@/types/Nui'
import { RoadMapSummaryData } from '@/components/roadmap/datamodel/types'
import GameChipControl from '@/components/game-chip-control.vue'
import { useI18n } from 'vue-i18n'
import { useCountdown } from '@/composables/useCountDown'
import CountDownTimer from '@/components/timer-count-down.vue'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import VideoLoader from '@/components/video-loader.vue'
import MobileChipControlH from '@/components/mobile-chip-control-horizontal.vue'

import pcNuiNuiClassicBetGroup from '@/components/bet/pc-nui-nui-classic-bet-group.vue'
import mobileNuiNuiClassicBetGroup from '@/components/bet/mobile-nui-nui-classic-bet-group.vue'
import pcNuiClassicCardResult from '@/components/custom-controls/pc-nui-classic-card-result-no-card.vue'
import mobileNuiClassicCardResult from '@/components/custom-controls/mobile-nui-classic-card-result-no-card.vue'
import jackpotBonus from '@/components/game/jackpot-bonus.vue'

import ExcelJS, { Alignment } from 'exceljs'
import { usePrintReport } from '@/composables/usePrintReport'

export default defineComponent({
    emits: ['show-dialog', 'show-switch-desk'],
    components: {
        'marquee-text': VTextMarquee,
        'icon-notice': IconNotice,
        'icon-question': iconQuestion,
        'icon-eye': SvgPwdToggle,
        'game-chip-control': GameChipControl,
        'svg-dolar': SvgDolar,
        'svg-full-screen-toggle': SvgFullScreenToggle,
        'svg-setting': SvgSetting,
        'timer': CountDownTimer,
        'pc-nui-classic-bet-group': pcNuiNuiClassicBetGroup,
        'mobile-nui-classic-bet-group': mobileNuiNuiClassicBetGroup,
        'pc-card-result': pcNuiClassicCardResult,
        'video-loader': VideoLoader,
        'm-chip-control-h': MobileChipControlH,
        'icon-thumbs-4': IconThumbsUp4,
        'm-d-card-result': mobileNuiClassicCardResult,
        'jackpotBonus': jackpotBonus
    },
    props: {
        desk: {
            default: -1,
            type: Number as PropType<number>
        },
        hall: {
            type: Number as PropType<number>,
            default: 1
        }
    },
    setup(props, { emit }) {
        const router = useRouter()
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()

        const deskNoticeKey = ref<number>(new Date().getTime())
        const deskNotice = ref<string>('')
        const deskData = ref<TableDetails | undefined>(undefined)
        const deskNoticeList = ref<Notice[]>([])

        const nuiData = ref(new NuiData([], 8))
        const chip = ref()

        const mapLeft = ref()
        const mapRight = ref()
        const mapMLeft = ref()
        const mapMRight = ref()
        const deskMapKey3 = ref<string>(`map3${new Date().getTime()}`)
        const deskMapKey4 = ref<string>(`map4${new Date().getTime()}`)
        const refBetGroup = ref<any>()
        const refMobileBetGroup = ref<any>()
        const hasTempBet = ref(false)
        const showCardDisplay = ref(false)

        const { printData } = usePrintReport()

        const displayOption = ref(0)
        const videoBg = ref(generateRandomNumber(1, 8))

        const betViewKey = ref<string>(`betview${new Date().getTime()}`)
        const deskMapKey1 = ref<string>(`map1${new Date().getTime()}`)
        const deskMapKey2 = ref<string>(`map2${new Date().getTime()}`)
        const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({})

        const displayMemberBetInfo = ref(true)

        const showJackpotWinnerInfo = ref(false)
        const jackPotTextStr = ref<BonusContent>({ message: '', won: false })

        const toastMessage = ref<
            { id: number | boolean | any; message: string }[]
        >([])

        let showCardTimeout: number | boolean | any = false
        const cardResultKey = ref<number>(Date.now())

        const winResult = ref<number[]>([])

        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any
        let loadDataFailedCounter = 0
        const deskVideoOn = ref<boolean>(false)
        const hideVideoCover = ref(false)
        let videoTimeOut: number | boolean | any = false
        const isZoom = ref(false)

        let videoState: 'default' | 'focus' = 'default'

        const displayTableInfo = ref(true)

        const isClassic = ref(true)

        const isLoadingVideo = ref<boolean>(true)

        const { currentCount, startCountDown, stopCountDown } = useCountdown(
            true,
            () => onCountDownStop(),
            () => {
                if (!isZoom.value) {
                    // isZoom.value = true
                    // change to zoom video
                    // initVideoPlayer()
                }
            }
        )

        //#region Vue Helpers

        onMounted(() => {
            deskVideoOn.value = videoIsOn.value

            removeallToast()
        })

        onBeforeMount(() => {
            emitter.on(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.on(EventBuss.SMALL_TOAST, handleSmallToastMessage)
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.on(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        onBeforeUnmount(() => {
            stopCountDown()
            disposeVideoPlayer()
            removeallToast()

            emitter.off(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
            emitter.off(EventBuss.SMALL_TOAST, handleSmallToastMessage)
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.off(EventBuss.WINDOW_RESIZED, handleOnWindowResize)
        })

        //#endregion

        //#region Click Helpers

        const toggleDisplayOptionView = () => {
            if (displayOption.value === 2) {
                displayOption.value = 0
            } else {
                displayOption.value++
            }
            deskMapKey3.value = `map3${new Date().getTime()}`
            deskMapKey4.value = `map4${new Date().getTime()}`
        }

        const backHistory = () => {
            // history.go(-1)
            router.push({ name: ROUTES.LOBY })
        }

        const predict = (i: number) => {
            if (mapLeft.value) {
                mapLeft.value.Predict(i)
            }

            if (mapRight.value) {
                mapRight.value.Predict(i)
            }

            if (mapMRight.value) {
                mapMRight.value.Predict(i)
            }

            if (mapMLeft.value) {
                mapMLeft.value.Predict(i)
            }
        }

        const toggleZoom = () => {
            isZoom.value = !isZoom.value
            initVideoPlayer()
        }

        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const confirmTempBet = (isMobile = false) => {
            if (deskData.value && deskData.value.gameTable.status === 1) {
                if (
                    refBetGroup.value &&
                    deviceOrientation.value === 'landscape'
                )
                    refBetGroup.value.confirmTempBet(
                        gameType.value,
                        gameTable.value
                    )

                if (
                    refMobileBetGroup.value &&
                    deviceOrientation.value === 'portrait'
                )
                    refMobileBetGroup.value.confirmTempBet(
                        gameType.value,
                        gameTable.value
                    )
            }
        }

        const cancelTempbet = (isMobile = false) => {
            if (hasTempBet.value) {
                if (refBetGroup.value) {
                    refBetGroup.value.resetTempBet(gameType.value)
                }

                if (refMobileBetGroup.value) {
                    refMobileBetGroup.value.resetTempBet(gameType.value)
                }
            }
        }

        const parseNuiNuiDataResults = () => {
            if (deskData.value) {
                let rowCount = 18
                if (deviceOrientation.value === 'portrait') rowCount = 11

                nuiData.value = new NuiData(
                    deskData.value.gameTable.expand.resultList as string[],
                    rowCount,
                    t
                )
            }
        }

        //#endregion

        //#region Helpers

        const formatNumberCurency = (n: number): string => {
            return formatWithCommas(n)
        }

        const handleOnWindowResize = () => {
            deskMapKey2.value = `map2${new Date().getTime()}`
            deskMapKey1.value = `map1${new Date().getTime()}`
            deskMapKey3.value = `map3${new Date().getTime()}`
            deskMapKey4.value = `map4${new Date().getTime()}`
        }

        const handleOnPageActive = () => {
            showCardDisplay.value = false
            showJackpotWinnerInfo.value = false
            disposeVideoPlayer()
            winResult.value = []
            isZoom.value = false

            loadData(true)
        }

        const handleSmallToastMessage = (message: {
            msg: string
            id: number
        }) => {
            if (deskData.value && deskData.value.gameTable.id === message.id) {
                const _t = {
                    id: setTimeout(() => {
                        const _tt = toastMessage.value.shift()
                        if (_tt && typeof _tt.id === 'number') {
                            clearTimeout(_tt.id)
                        }
                    }, 1500),
                    message: message.msg
                }
                toastMessage.value.push(_t)
            }
        }
        const removeallToast = () => {
            toastMessage.value.forEach((toastM) => {
                if (typeof toastM.id === 'number') {
                    clearTimeout(toastM.id)
                    toastM.id = false
                }
            })

            toastMessage.value = []
        }
        const handleBetgroupUpdate = (_hasTempBet: boolean) => {
            hasTempBet.value = _hasTempBet
        }

        const onCountDownStop = () => {
            cancelTempbet()
        }

        const showUiGameResult = (_results: number[]) => {
            winResult.value = _results
        }

        const toggleShowExtraLimitInfo = () => {
            displayTableInfo.value = !displayTableInfo.value
            // store.commit(
            //     AppMutation.SET_DISPLAY_TABLE_INFO,
            //     !displayTableInfo.value
            // )
        }
        //#endregion

        //#region API CALL
        const loadData = (_forceReload = false) => {
            if (props.desk <= 0) {
                router.push({ name: ROUTES.LOBY })
                return
            }

            const _params = {
                memberId: memberid.value,
                tableId: props.desk,
                token: token.value
            }

            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)
            getTableDetail(_params)
                .then((_response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData =
                        _response.data as ResponseData<TableDetails>

                    if (responseData.status === 200) {
                        if (
                            responseData.data?.gameTable.gameTypeNo === 'G001'
                        ) {
                            // router.push({ name: ROUTES.LOBY })
                            // return
                        }

                        const { noticeList } = responseData.data!

                        deskNoticeList.value = noticeList

                        sortNotice()

                        deskData.value = responseData.data!

                        // isZoom.value = deskData.value.gameTable.status === 2

                        store.commit(
                            AppMutation.SET_COIN,
                            deskData.value.gameTable.expand.amount
                        )

                        store.commit(
                            AppMutation.SET_JACPOT_BET_AMOUNT,
                            parseFloat(deskData.value.jackPotBetAmount) || 0
                        )

                        parseNuiNuiDataResults()

                        // nuiData.value = new NuiData(
                        //     deskData.value.gameTable.expand
                        //         .resultList as string[],
                        //     8
                        // )
                        // roadMapData.value = parseRoadMap(
                        //     deskData.value.gameTable.expand.resultList
                        // )

                        if (refBetGroup.value || refMobileBetGroup.value) {
                            setTimeout(() => {
                                if (refBetGroup.value)
                                    refBetGroup.value.resetBetUI(
                                        gameType.value,
                                        gameTable.value
                                    )

                                if (refMobileBetGroup.value)
                                    refMobileBetGroup.value.resetBetUI(
                                        gameType.value,
                                        gameTable.value
                                    )

                                if (
                                    deskData.value &&
                                    deskData.value.gameTable.expand.betFormList
                                ) {
                                    if (deskData.value.gameTable.status === 1) {
                                        deskData.value.gameTable.expand.betFormList.forEach(
                                            (i) => {
                                                if (
                                                    i.memberId ===
                                                    memberid.value
                                                ) {
                                                    // enableOpenPoker.value = true
                                                }
                                            }
                                        )
                                    }
                                }
                            }, 250)
                        }

                        if (deskVideoOn.value) {
                            initVideoPlayer()
                        }
                    } else if (responseData.status === 301) {
                        // token is expired
                        // reset
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    // emitter.emit(EventBuss.TOAST_MESSAGE, '网络错误')

                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,

                            {
                                msg: t('prompt.networkerror'),
                                id: deskData.value?.gameTable.id || 0
                            }
                        )

                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const sortNotice = () => {
            let _deskNotice: string[] = []
            deskNoticeList.value.forEach((_notice, index) => {
                if (
                    index === 0 &&
                    _notice.isPop === 0 &&
                    _notice.langs === langToNoticeLanguageValue(lang.value)
                ) {
                    _deskNotice.push(_notice.content)
                } else if (
                    index !== 0 &&
                    _notice.isPop === 0 &&
                    _notice.langs === langToNoticeLanguageValue(lang.value)
                ) {
                    _deskNotice.push(_notice.content)
                }
            })
            deskNotice.value = _deskNotice.join(',')
            deskNoticeKey.value = new Date().getTime()
        }

        const getPrintDetails = () => {
            const params = {
                memberId: memberid.value,
                tableNo: deskData.value?.gameTable.tableNo || 0,
                gameNo: deskData.value?.gameTable.expand.gameNo || 0,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            getPrintDetailsFromApi(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const responseData = response.data.data

                    printData(responseData)

                    printSuccess(params)
                        .then(() => {
                            //
                        })
                        .catch(() => {
                            //
                        })
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        //#endregion

        //#region Websocket Helpers

        const handleSocketMessage = (_message: SocketMessage | any) => {
            const { type, gameTable } = _message
            const _type = type.toUpperCase()

            if (_type === SOCKET_MESSAGE.NEW_GAME) {
                const sDesk = gameTable! as TableData

                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.expand.shoeNo = sDesk.expand.shoeNo
                    _gtableCopy.expand.juNo = sDesk.expand.juNo
                    _gtableCopy.expand.gameNo = sDesk.expand.gameNo
                    _gtableCopy.expand.daoJiShiBetTime =
                        sDesk.expand.daoJiShiBetTime

                    // reset all data
                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    // reset card result
                    _gtableCopy.expand.playerOne = undefined
                    _gtableCopy.expand.playerTwo = undefined
                    _gtableCopy.expand.playerThree = undefined
                    _gtableCopy.expand.bankerOne = undefined
                    _gtableCopy.expand.bankerTwo = undefined
                    _gtableCopy.expand.bankerThree = undefined
                    _gtableCopy.expand.playerCount = undefined
                    _gtableCopy.expand.bankerCount = undefined
                    _gtableCopy.expand.whoWin = -1

                    _gtableCopy.expand.oneNo = 0
                    _gtableCopy.expand.oneTotalAmount = 0
                    _gtableCopy.expand.twoNo = 0
                    _gtableCopy.expand.twoTotalAmount = 0
                    _gtableCopy.expand.threeNo = 0
                    _gtableCopy.expand.threeTotalAmount = 0

                    _gtableCopy.downLimit = sDesk.downLimit

                    _gtableCopy.status = 1

                    deskData.value!.gameTable = _gtableCopy
                    if (isZoom.value) {
                        isZoom.value = false
                        initVideoPlayer()
                    }

                    emitter.emit(EventBuss.GAMESTATUS, 1)
                }
            } else if (_type === SOCKET_MESSAGE.END_GAME) {
                const sDesk = gameTable! as TableData
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    console.log(sDesk)

                    _gtableCopy.expand.playerOne = sDesk.expand.playerOne
                    _gtableCopy.expand.playerTwo = sDesk.expand.playerTwo
                    _gtableCopy.expand.playerThree = sDesk.expand.playerThree
                    _gtableCopy.expand.bankerOne = sDesk.expand.bankerOne
                    _gtableCopy.expand.bankerTwo = sDesk.expand.bankerTwo
                    _gtableCopy.expand.bankerThree = sDesk.expand.bankerThree
                    _gtableCopy.expand.playerCount = sDesk.expand.playerCount
                    _gtableCopy.expand.bankerCount = sDesk.expand.bankerCount
                    _gtableCopy.expand.whoWin = sDesk.expand.whoWin
                    _gtableCopy.expand.resultList = sDesk.expand.resultList
                    _gtableCopy.expand.gameRecordList =
                        sDesk.expand.gameRecordList

                    // reset bet data
                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    _gtableCopy.expand.daoJiShiBetTime = -1
                    _gtableCopy.downLimit = sDesk.downLimit

                    _gtableCopy.expand.gameTableJackpotList =
                        sDesk.expand.gameTableJackpotList
                    _gtableCopy.expand.memberTotalAmount = 0
                    _gtableCopy.expand.memberTotalSecurityDepositAmount = 0
                    //
                    _gtableCopy.expand.oneNo = 0
                    _gtableCopy.expand.oneTotalAmount = 0
                    _gtableCopy.expand.twoNo = 0
                    _gtableCopy.expand.twoTotalAmount = 0
                    _gtableCopy.expand.threeNo = 0
                    _gtableCopy.expand.threeTotalAmount = 0

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]
                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberid.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            if (_memberResultInfo.winResult !== 0) {
                                let _amtStr = '0'

                                if (_memberResultInfo.winResult > 0) {
                                    _amtStr = `+${formatNumberCurency(
                                        _memberResultInfo.winResult
                                    )}`

                                    // play win sound
                                    audioPlayer.Play(SOUNDKEY.WIN_SOUND_EFFECT)
                                } else
                                    _amtStr = String(
                                        formatNumberCurency(
                                            _memberResultInfo.winResult
                                        )
                                    )

                                emitter.emit(EventBuss.SMALL_TOAST, {
                                    msg: _amtStr,
                                    id: deskData.value?.gameTable.id || 0
                                })
                            }
                        }
                    }

                    deskData.value!.gameTable = _gtableCopy

                    // parse NuiNui Result
                    parseNuiNuiDataResults()

                    if (typeof showCardTimeout === 'number') {
                        clearTimeout(showCardTimeout)
                        showCardTimeout = false
                        showCardDisplay.value = false
                    }

                    if (_gtableCopy.expand.whoWin) {
                        const _whoWin = String(_gtableCopy.expand.whoWin)
                        const gameResult = Nui.Parse(_whoWin)

                        const _winResult: number[] = []
                        if (gameResult.player1.win) _winResult.push(1)
                        if (gameResult.player2.win) _winResult.push(2)
                        if (gameResult.player3.win) _winResult.push(3)

                        showUiGameResult(_winResult)
                    }

                    cardResultKey.value = Date.now()
                    showCardDisplay.value = true
                    if (isZoom.value) {
                        isZoom.value = false
                        initVideoPlayer()
                    }

                    // show jackpot winner
                    if (typeof sDesk.expand.getJackpotInfo === 'object') {
                        const jpInf = sDesk.expand.getJackpotInfo

                        // ₫
                        let currencytext = '$'
                        let jackpotWinAmount = 0
                        const crcy = currency.value
                        if (crcy === GameCurrencies.JPY) {
                            jackpotWinAmount = jpInf.winJackpotAmountJpy
                            currencytext = '¥'
                        } else if (crcy === GameCurrencies.KRW) {
                            jackpotWinAmount = jpInf.winJackpotAmountKrw
                            currencytext = '₩'
                        } else if (crcy === GameCurrencies.THB) {
                            jackpotWinAmount = jpInf.winJackpotAmountThb
                            currencytext = '฿'
                        } else if (
                            crcy === GameCurrencies.USD ||
                            crcy === GameCurrencies.USDT
                        ) {
                            jackpotWinAmount = jpInf.winJackpotAmountUsd
                            currencytext = '$'
                        } else if (crcy === GameCurrencies.VND) {
                            jackpotWinAmount = jpInf.winJackpotAmountVnd
                            currencytext = '₫'
                        }

                        if (typeof jackpotWinAmount !== 'undefined') {
                            let bonusTypes: string[] = [
                                NiuBonusType.FLUSH,
                                NiuBonusType.ROYAL_FLUSH,
                                NiuBonusType.FOUR_KIND,
                                NiuBonusType.STRAIGHT,
                                NiuBonusType.STRAIGHT_FLUSH,
                                NiuBonusType.THREE_KIND,
                                NiuBonusType.FULL_HOUSE
                            ]

                            let bonusText: string[] = []
                            let toPushText = ''

                            if (bonusTypes.indexOf(jpInf.player1) !== -1) {
                                toPushText = `${t(
                                    bonusTypeToStringValue(jpInf.player1)
                                )}`
                                if (bonusText.indexOf(toPushText) === -1)
                                    bonusText.push(toPushText)
                            }

                            if (bonusTypes.indexOf(jpInf.player2) !== -1) {
                                toPushText = `${t(
                                    bonusTypeToStringValue(jpInf.player2)
                                )}`

                                if (bonusText.indexOf(toPushText) === -1)
                                    bonusText.push(toPushText)
                            }

                            if (bonusTypes.indexOf(jpInf.player3) !== -1) {
                                toPushText = `${t(
                                    bonusTypeToStringValue(jpInf.player3)
                                )}`

                                if (bonusText.indexOf(toPushText) === -1)
                                    bonusText.push(toPushText)
                            }

                            jackPotTextStr.value.message = t(
                                'ingame.jackpotmessage',
                                [
                                    tableName.value,
                                    bonusText.join(','),
                                    `${currencytext}${formatWithCommas(
                                        jackpotWinAmount
                                    )}`
                                ]
                            )
                            jackPotTextStr.value.won =
                                jpInf.memberList.indexOf(memberid.value) !== -1
                            showJackpotWinnerInfo.value = true
                        }
                    }

                    showCardTimeout = setTimeout(() => {
                        showCardDisplay.value = false
                        winResult.value = []
                        showJackpotWinnerInfo.value = false
                        jackPotTextStr.value = { message: '', won: false }
                    }, 15000)

                    if (refBetGroup.value || refMobileBetGroup.value) {
                        setTimeout(() => {
                            if (refBetGroup.value)
                                refBetGroup.value.endGame(gameType.value)
                            if (refMobileBetGroup.value)
                                refMobileBetGroup.value.endGame(gameType.value)
                        }, 250)
                    }
                }
            } else if (_type === SOCKET_MESSAGE.CANCEL_GAME) {
                const sDesk = gameTable! as TableData
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }
                    _gtableCopy.status = sDesk.status

                    _gtableCopy.downLimit = sDesk.downLimit

                    _gtableCopy.expand.betFormList = []
                    _gtableCopy.expand.xianAmount = 0
                    _gtableCopy.expand.xianDuiAmount = 0
                    _gtableCopy.expand.zhuangAmount = 0
                    _gtableCopy.expand.zhuangDuiAmount = 0
                    _gtableCopy.expand.heAmount = 0
                    _gtableCopy.expand.daoJiShiBetTime = -1

                    _gtableCopy.expand.gameTableJackpotList =
                        sDesk.expand.gameTableJackpotList
                    _gtableCopy.expand.memberTotalAmount = 0
                    _gtableCopy.expand.memberTotalSecurityDepositAmount = 0

                    // reset values
                    _gtableCopy.expand.oneNo = 0
                    _gtableCopy.expand.oneTotalAmount = 0
                    _gtableCopy.expand.twoNo = 0
                    _gtableCopy.expand.twoTotalAmount = 0
                    _gtableCopy.expand.threeNo = 0
                    _gtableCopy.expand.threeTotalAmount = 0

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]

                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberid.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            if (refBetGroup.value || refMobileBetGroup.value) {
                                setTimeout(() => {
                                    if (refBetGroup.value)
                                        refBetGroup.value.endGame()
                                    if (refMobileBetGroup.value)
                                        refMobileBetGroup.value.endGame()
                                }, 250)
                            }
                        }
                    }

                    deskData.value!.gameTable = _gtableCopy
                    showCardDisplay.value = false
                    showJackpotWinnerInfo.value = false
                }
            } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                const sDesk = gameTable!
                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.downLimit = sDesk.downLimit
                    // _gtableCopy.upLimit = sDesk.upLimit
                    _gtableCopy.expand.daoJiShiBetTime = 0
                    _gtableCopy.status = 2
                    deskData.value!.gameTable = _gtableCopy
                    audioPlayer.Play(SOUNDKEY.STOP_BET)
                    emitter.emit(EventBuss.GAMESTATUS, 2)
                }
            } else if (_type === SOCKET_MESSAGE.BET) {
                const sDesk = gameTable! as TableData

                if (isSameTable(sDesk)) {
                    const _gtableCopy = { ...deskData.value!.gameTable }

                    _gtableCopy.expand.xianAmount = sDesk.expand.xianAmount
                    _gtableCopy.expand.xianDuiAmount =
                        sDesk.expand.xianDuiAmount
                    _gtableCopy.expand.zhuangAmount = sDesk.expand.zhuangAmount
                    _gtableCopy.expand.zhuangDuiAmount =
                        sDesk.expand.zhuangDuiAmount
                    _gtableCopy.expand.heAmount = sDesk.expand.heAmount
                    _gtableCopy.downLimit = sDesk.downLimit

                    _gtableCopy.expand.gameTableJackpotList =
                        sDesk.expand.gameTableJackpotList

                    _gtableCopy.expand.oneNo = sDesk.expand.oneNo
                    _gtableCopy.expand.oneTotalAmount =
                        sDesk.expand.oneTotalAmount
                    _gtableCopy.expand.twoNo = sDesk.expand.twoNo
                    _gtableCopy.expand.twoTotalAmount =
                        sDesk.expand.twoTotalAmount
                    _gtableCopy.expand.threeNo = sDesk.expand.threeNo
                    _gtableCopy.expand.threeTotalAmount =
                        sDesk.expand.threeTotalAmount

                    if (sDesk.expand.memberId === memberid.value) {
                        _gtableCopy.expand.betFormList =
                            sDesk.expand.betFormList

                        _gtableCopy.expand.memberTotalAmount =
                            sDesk.expand.memberTotalAmount
                        _gtableCopy.expand.memberTotalSecurityDepositAmount =
                            sDesk.expand.memberTotalSecurityDepositAmount
                        store.commit(AppMutation.SET_COIN, sDesk.expand.amount)
                        // store.commit(AppMutation.SET_CLIENT_TIME_OUT,15)
                    }
                    deskData.value!.gameTable = _gtableCopy
                }
            } else if (_type === SOCKET_MESSAGE.NEW_SHOE) {
                const sDesk = gameTable!
                if (isSameTable(sDesk) && deskData.value) {
                    sDesk.upLimit = deskData.value.gameTable.upLimit
                    deskData.value.gameTable = sDesk
                }
            } else if (_type === SOCKET_MESSAGE.READ_POKER) {
                // const sDesk = gameTable!
                // if (isSameTable(sDesk)) {
                //     const _gtableCopy = { ...deskData.value!.gameTable }
                //     _gtableCopy.expand.playerOne = sDesk.expand.playerOne
                //     _gtableCopy.expand.playerTwo = sDesk.expand.playerTwo
                //     _gtableCopy.expand.playerThree = sDesk.expand.playerThree
                //     _gtableCopy.expand.bankerOne = sDesk.expand.bankerOne
                //     deskData.value!.gameTable = _gtableCopy
                //     showCardDisplay.value = true
                // }
            }
        }

        const isSameTable = (_table: TableData): boolean => {
            if (deskData.value && deskData.value.gameTable.id === _table.id)
                return true
            else return false
        }

        //#endregion
        //#region Video Player Helpers
        const initVideoPlayer = () => {
            isLoadingVideo.value = true
            disposeVideoPlayer()
            const videoPaths = [process.env.VIDEO1, process.env.VIDEO2]
            let isVideoConnected = false
            const videoUrl = deskData.value?.gameTable.cmccLine || ''
            let ag = isZoom.value ? 'ag2' : 'ag'
            
            videoPaths.forEach((vPath) => {
                //const path1 = videoUrl
                    //.replace(/(http:|)(^|\/\/)(.*?\/)/g, '')
                    //.split('/')[1]

            //const _videoUrl = `${vPath}/${ag}/${path1}`


            //const _videoUrl = videoUrl
            const _videoUrl = isZoom.value 
    ? videoUrl  // 如果 isZoom.value 为 true，直接使用 videoUrl
    : videoUrl.replace('1.live', '2.live');  // 如果 isZoom.value 为 false，将 1.live 替换为 2.live

                // eslint-disable-next-line no-undef
                let _player: NodePlayer | any = new NodePlayer()

                _player.setVolume(0.0)
                // _player.setScaleLevel(0.5)

                if (getDeviceOrientation() === 'landscape') {
                    _player.setView('videoplayer2')
                    // set scale mode to normal
                    _player.setScaleMode(0)
                } else {
                    _player.setView('m-videoplayer2')
                    if (isZoom.value) {
                        _player.setScaleMode(1)
                    } else {
                        _player.setScaleMode(2)
                    }
                }

                _player.setBufferTime(1000)
                // _player.setCanvasScaleLevel(100, 100, 1.2)
                // _player.setScaleLevel(100, 100, 1)

                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true
                        nodePlayer = _player
                        // nodePlayer.clearView()
                        if (getDeviceOrientation() === 'landscape') {
                            nodePlayer.setView('videoplayer')
                        } else {
                            nodePlayer.setView('m-videoplayer')
                        }
                        nodePlayer.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    isLoadingVideo.value = true
                                } else if (evt === 'full') {
                                    isLoadingVideo.value = false
                                    if (!hideVideoCover.value)
                                        hideVideoCover.value = true
                                }
                            }
                        )
                        nodePlayer.on('error', (e: any) => {
                            isLoadingVideo.value = true
                            setTimeout(() => {
                                nodePlayer.stop()
                                nodePlayer.clearView()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        _player.stop()
                        _player.clearView()
                    }
                })
                _player.on('error', () => {
                    //
                })
                videoPlayerCollection.push(_player)
                _player.start(_videoUrl)
            })
        }
        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }

            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.stop()
                    _player.clearView()
                }
            })

            videoPlayerCollection = []
        }
        //#endregion

        //#region Computed
        const memberid = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])
        const videoIsOn = computed(() => store.getters['videoIsOn'])
        const coin = computed(() => store.getters['coin'])
        const user = computed(() => store.getters['user'])
        const isMobile = computed(() => store.getters['isMobile'])
        const isfullScreen = computed(() => store.getters['isfullScreen'])
        // const displayTableInfo = computed(
        //     (): boolean => store.getters['displayTableInfo']
        // )

        const mapData = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.expand.resultList
            return []
        })

        const gameType = computed(() => {
            if (deskData.value)
                return deskData.value.gameTable.gameTypeNo.toUpperCase()

            return 'G002'
        })

        const deskCommissionStatus = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.commissionStatus === 1
            }
            return false
        })

        const memberCommisionStatus = computed(() => {
            if (deskData.value) {
                return (
                    deskData.value.gameTable.expand.memberCommissionStatus === 1
                )
            }
            return true
        })

        const betRedCommision = computed(() => {
            if (deskCommissionStatus.value) {
                return memberCommisionStatus.value
            }

            return true
        })

        const gameTableDownLimit = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.downLimit
            }
            return '0'
        })

        const deviceOrientation = computed(
            (): 'portrait' | 'landscape' => store.getters['deviceOrientation']
        )

        const gameTable = computed(() => {
            if (deskData.value) return deskData.value.gameTable

            return undefined
        })

        const tableName = computed(() => {
            if (deskData.value) {
                const { gameTypeNo, tableNo } = deskData.value.gameTable

                return `${t('desklist.niuniutable', {
                    tablenumber: tableNo
                })}`
            }
            return ''
        })
        const minAndMaxTableLimit = computed(() => {
            if (deskData.value) {
                const { gameTypeNo } = deskData.value.gameTable

                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const {
                        downBaccaratLimit,
                        upBaccaratLimit,
                        downDragonLimit,
                        upDragonLimit
                    } = limit

                    if (gameTypeNo === 'G002') {
                        return `${downBaccaratLimit}-${upBaccaratLimit}`
                    } else if (gameTypeNo === 'G003') {
                        return `${downDragonLimit}-${upDragonLimit}`
                    } else {
                        return '0-0'
                    }
                }

                return '0-0'
            }
            return '0-0'
        })

        const gameStatus = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.status
            }

            return 0
        })

        const maxTime = computed(() => {
            if (deskData.value) return deskData.value.gameTable.betTime
            return -1
        })

        const gameid = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.expand.gameNo
            }

            return '0'
        })

        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )

        const lang = computed((): string => store.getters['lang'])
        const userLimit = computed((): number => store.getters.userLimit)
        const currency = computed((): string => store.getters['currency'])

        const gameTableUpLimit = computed(() => {
            if (deskData.value) {
                return deskData.value.gameTable.upLimit
            }
            return '0'
        })

        const superLimit = computed(() => {
            if (deskData.value) {
                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const { downSuperLimit, upSuperLimit } = limit

                    return `${formatNumberCurency(
                        downSuperLimit
                    )}-${formatNumberCurency(upSuperLimit)}`
                }

                return '0-0'
            }
            return '0-0'
        })

        const doubleLimit = computed(() => {
            if (deskData.value) {
                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const { downFanLimit, upFanLimit } = limit

                    return `${formatNumberCurency(
                        downFanLimit
                    )}-${formatNumberCurency(upFanLimit)}`
                }

                return '0-0'
            }
            return '0-0'
        })

        const evenLimit = computed(() => {
            if (deskData.value) {
                const limit = limitOptionArray.value.find(
                    (s) => s.id === userLimit.value
                )

                if (limit) {
                    const { downPingLimit, upPingLimit } = limit

                    return `${formatNumberCurency(
                        downPingLimit
                    )}-${formatNumberCurency(upPingLimit)}`
                }

                return '0-0'
            }
            return '0-0'
        })

        const displayOptionLabel = computed(() => {
            const displayValue = displayOption.value
            if (displayValue === 0) return t('common.allroad')
            else if (displayValue === 1) return t('common.mainroad')
            else return t('common.statistics')
        })

        const jackPotValue = computed((): string => {
            let amt = 0
            if (deskData.value) {
                const c = currency.value

                const jackPotInfo =
                    deskData.value.gameTable.expand.gameTableJackpotList.find(
                        (cb) => {
                            // cb.currencyNo === c

                            if (
                                (cb.currencyNo === 'USD' ||
                                    cb.currencyNo === 'USDT') &&
                                (c === 'USD' || c === 'USDT')
                            ) {
                                return true
                            } else {
                                return cb.currencyNo === c
                            }
                        }
                    )

                if (jackPotInfo) amt = jackPotInfo.jackPot
            }

            return formatNumberCurency(amt)
        })

        const memberTotalAmount = computed(() => {
            if (deskData.value)
                return formatNumberCurency(
                    deskData.value.gameTable.expand.memberTotalAmount
                )
            return 0
        })

        const memberTotalSecurityDepositAmount = computed(() => {
            if (deskData.value)
                return formatNumberCurency(
                    deskData.value.gameTable.expand
                        .memberTotalSecurityDepositAmount
                )
            return 0
        })

        //#endregion

        //#region Watcher

        watch(
            () => videoIsOn.value,
            (v) => {
                if (v !== deskVideoOn.value) {
                    deskVideoOn.value = v

                    if (deskVideoOn.value) {
                        if (deskData.value) initVideoPlayer()
                    } else {
                        disposeVideoPlayer()
                    }
                }
            }
        )

        watch(
            () => props.desk,
            (v) => {
                stopCountDown()

                showCardDisplay.value = false
                showJackpotWinnerInfo.value = false
                betViewKey.value = `betview${new Date().getTime()}`
                winResult.value = []
                isZoom.value = false
                deskData.value = undefined
                nuiData.value = new NuiData([], 8)
                hideVideoCover.value = false
                jackPotTextStr.value = { message: '', won: false }

                loadData()

                videoBg.value = generateRandomNumber(1, 8)
            },
            { immediate: true }
        )

        watch(
            () => deskData.value,
            (v) => {
                if (v) {
                    if (v.gameTable.expand.daoJiShiBetTime > 0) {
                        startCountDown(v.gameTable.expand.daoJiShiBetTime)
                    } else {
                        stopCountDown()
                    }
                }
            },
            { deep: true }
        )

        watch(
            () => currentCount.value,
            (v) => {
                if (v === -1) {
                    if (deskData.value) {
                        deskData.value.gameTable.status = 2
                        deskData.value.gameTable.expand.daoJiShiBetTime = -1
                    }
                }
            }
        )

        watch(
            () => deviceOrientation.value,
            (v) => {
                if (refMobileBetGroup.value)
                    refMobileBetGroup.value.resetTempBet(gameType.value)
                if (refBetGroup.value)
                    refBetGroup.value.resetTempBet(gameType.value)

                if (typeof videoTimeOut === 'number') {
                    clearTimeout(videoTimeOut)
                    videoTimeOut = false
                }
                hideVideoCover.value = false
                disposeVideoPlayer()
                videoTimeOut = setTimeout(() => {
                    initVideoPlayer()
                }, 500)
            }
        )

        watch(
            () => lang.value,
            () => {
                parseNuiNuiDataResults()
                sortNotice()
            }
        )

        //#endregion

        return {
            jackPotTextStr,
            showJackpotWinnerInfo,
            displayMemberBetInfo,
            memberTotalAmount,
            memberTotalSecurityDepositAmount,
            nuiData,
            isZoom,
            videoBg,
            toastMessage,
            refMobileBetGroup,
            refBetGroup,
            hasTempBet,
            deskNotice,
            deskNoticeKey,
            mapData,
            deskMapKey1,
            deskMapKey2,
            isMobile,
            nextRedRoadMapSummaryData,
            nextBlueRoadMapSummaryData,
            gameType,
            mapLeft,
            mapRight,
            mapMLeft,
            mapMRight,
            coin,
            user,
            chip,

            tableName,
            gameid,
            minAndMaxTableLimit,
            superLimit,
            doubleLimit,
            evenLimit,

            isfullScreen,
            currentCount,
            gameStatus,
            maxTime,
            betViewKey,
            gameTable,
            betRedCommision,
            winResult,
            showCardDisplay,
            deviceOrientation,
            deskData,
            displayOption,
            hideVideoCover,
            isLoadingVideo,
            isClassic,
            gameTableDownLimit,
            gameTableUpLimit,
            deskMapKey4,
            deskMapKey3,
            displayOptionLabel,
            displayTableInfo,
            handleBetgroupUpdate,
            requestFullScreen,
            predict,
            jackPotValue,
            confirmTempBet,
            cancelTempbet,
            backHistory,
            toggleDisplayOptionView,
            showUiGameResult,
            t,
            toggleShowExtraLimitInfo,
            toggleZoom,
            formatNumberCurency,
            getPrintDetails
        }
    }
})
