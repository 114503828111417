import { useStore } from '@/store'
import { ChipItem, ChipUI, StyleValue, TableData, computed, ref } from 'vue'

export function useBaccaratAndDragontigerBetBox(
    betType: any,
    rect: {
        offsetTop: number
        offsetLeft: number
        w: number
        h: number
    },
    chipsAttr = { width: 20, height: 12, offset: 5 },
    display: 'pc' | 'mobile'
) {
    const store = useStore()

    const chipsUI = ref<ChipUI[]>([])

    const generatePosition = () => {
        const postion = { x: 0, y: 0 }

        const maxX =
            rect.w - (chipsAttr.width + chipsAttr.offset) + chipsAttr.offset
        const maxY = rect.h - chipsAttr.height

        const randomX = Math.floor(Math.random() * maxX)

        const randomY = Math.floor(Math.random() * maxY)

        postion.x = Math.floor(randomX)
        postion.y = Math.floor(randomY)

        return postion
    }

    const handleOnPlaceChip = (arg: {
        t: number | any
        amt: number
        type: 'temp' | 'confirmed'
        deskId: number
        chip?: ChipItem
        desk?: TableData
    }) => {
        if (arg.desk && arg.desk.id !== arg.deskId) return

        if (arg.t !== betType) return

        if (
            arg.type === 'confirmed' &&
            chipsUI.value.length === 1 &&
            chipsUI.value[0].amount === arg.amt
        ) {
            return
        } else if (arg.type === 'confirmed') {
            chipsUI.value = []
        }

        const position = generatePosition()

        if (arg.chip) {
            let chipCLass = `${arg.chip.id}`
            if (arg.type === 'confirmed') {
                const chipDef = chipDefault.value.find(
                    (x) => x.value === arg.amt
                )
                if (chipDef) {
                    chipCLass = `${chipDef.id}`
                } else {
                    chipCLass = `${
                        chipDefault.value[chipDefault.value.length - 1].id
                    }`
                }
            }

            chipsUI.value.push({
                x: position.x,
                y: position.y,
                amount: arg.amt,
                class: chipCLass,
                type: arg.type
            })
        }
    }

    const resetBetUI = () => {
        chipsUI.value = []
    }
    const applyTempUIBet = () => {
        chipsUI.value = chipsUI.value.map((i) => {
            if (i.type === 'temp') i.type = 'confirmed'
            return i
        })
    }

    const chipStyle = (x: number, y: number): StyleValue => {
        // top:var(--pc${chip.y});left:var(--pc${chip.x});
        const _prefix = display === 'pc' ? '--pc' : '--mp'
        const top = `var(${_prefix + Math.floor(y)})`
        const left = `var(${_prefix + Math.floor(x)})`
        return {
            top,
            left
        }
    }

    const removeTempUIBet = () => {
        chipsUI.value = chipsUI.value.filter((i) => i.type !== 'temp')
    }

    const chipWrapStyle = computed((): StyleValue => {
        if (rect) {
            const { offsetLeft, w, h, offsetTop } = rect
            const _prefix = display === 'pc' ? '--pc' : '--mp'
            const left = `var(${_prefix + Math.floor(offsetLeft)})`
            const width = `var(${_prefix + Math.floor(w)})`
            const height = `var(${_prefix + Math.floor(h)})`
            const top = `var(${_prefix + Math.floor(offsetTop)})`
            return {
                position: 'absolute',
                // backgroundColor: 'green',
                bottom: 'auto',
                top,
                left,
                right: 'auto',
                width,
                height
            }
        }

        return {}
    })

    const chipDefault = computed((): ChipItem[] => store.getters['chipDefault'])

    return {
        handleOnPlaceChip,
        resetBetUI,
        applyTempUIBet,
        chipStyle,
        removeTempUIBet,
        chipsUI,
        chipWrapStyle
    }
}
