import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-782f85ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-nui-classic-card-result-root" }
const _hoisted_2 = { class: "red" }
const _hoisted_3 = { class: "cards-info" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "blue" }
const _hoisted_6 = { class: "cards-info" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "cards-info" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "cards-info" }
const _hoisted_11 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, "B " + _toDisplayString(_ctx.winResult.banker.value), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, "P1 " + _toDisplayString(_ctx.winResult.player1.value), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, "P2 " + _toDisplayString(_ctx.winResult.player2.value), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, "P3 " + _toDisplayString(_ctx.winResult.player3.value), 1)
      ])
    ])
  ]))
}