import { saveBet } from '@/api'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { EventBuss, LANG_CODE } from '@/types/global'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { ChipItem, Emitter, TableData, computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useNiuNiuBetgroup(
    emit: (event: 'on-update', ...args: any[]) => void,
    playSoundEffect = false
) {
    const emitter = inject('emitter') as Emitter
    const { t } = useI18n()
    const store = useStore()

    const p1jBet = ref(0)
    const p1sBet = ref(0)
    const p11Bet = ref(0)
    const p12Bet = ref(0)

    const p2jBet = ref(0)
    const p2sBet = ref(0)
    const p21Bet = ref(0)
    const p22Bet = ref(0)

    const p3jBet = ref(0)
    const p3sBet = ref(0)
    const p31Bet = ref(0)
    const p32Bet = ref(0)

    const p1jBetTemp = ref(0)
    const p1sBetTemp = ref(0)
    const p11BetTemp = ref(0)
    const p12BetTemp = ref(0)

    const p2jBetTemp = ref(0)
    const p2sBetTemp = ref(0)
    const p21BetTemp = ref(0)
    const p22BetTemp = ref(0)

    const p3jBetTemp = ref(0)
    const p3sBetTemp = ref(0)
    const p31BetTemp = ref(0)
    const p32BetTemp = ref(0)

    const p1jBox = ref()
    const p1sBox = ref()
    const p11Box = ref()
    const p12Box = ref()

    const p2jBox = ref()
    const p2sBox = ref()
    const p21Box = ref()
    const p22Box = ref()

    const p3jBox = ref()
    const p3sBox = ref()
    const p31Box = ref()
    const p32Box = ref()

    const placeAbet = (
        e: MouseEvent,
        _betType:
            | 'P1_J'
            | 'P1_S'
            | 'P1_2'
            | 'P1_1'
            | 'P2_J'
            | 'P2_S'
            | 'P2_2'
            | 'P2_1'
            | 'P3_J'
            | 'P3_S'
            | 'P3_2'
            | 'P3_1',
        mData?: TableData,
        chip?: ChipItem
    ) => {
        if (mData) {
            if (mData.status !== 1) {
                emitter.emit(EventBuss.SMALL_TOAST, {
                    msg: t('prompt.bettingnotallowed'),
                    id: mData.id
                })
                return
            }

            let _amount = chip?.value || 0

            if (
                _betType === 'P1_J' ||
                _betType === 'P2_J' ||
                _betType === 'P3_J'
            ) {
                // _amount = 2000
                _amount = jackpotBetAmount.value

                let tempJ = 0
                if (_betType === 'P1_J') {
                    tempJ = p1jBet.value + p1jBetTemp.value + _amount
                } else if (_betType === 'P2_J') {
                    tempJ = p2jBet.value + p2jBetTemp.value + _amount
                } else if (_betType === 'P3_J') {
                    tempJ = p3jBet.value + p3jBetTemp.value + _amount
                }

                if (tempJ > jackpotBetAmount.value) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: t('prompt.jackpotmaxbet', [
                            jackpotBetAmount.value
                        ]),
                        id: mData?.id || 0
                    })

                    return
                }
            }

            if (_amount > 0) {
                if (!isBetIsSufficient(_amount, _betType)) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: t('prompt.insufficientbalance'),
                        id: mData?.id || 0
                    })

                    if (
                        _betType === 'P1_J' ||
                        _betType === 'P2_J' ||
                        _betType === 'P3_J'
                    ) {
                        return
                    }

                    // check if balance is sufficient check

                    _amount = getFillAmount(_betType)

                    if (_amount === 0) return
                }
            }

            switch (_betType) {
                case 'P1_J':
                    p1jBetTemp.value += _amount
                    break
                case 'P1_S':
                    p1sBetTemp.value += _amount
                    break
                case 'P1_2':
                    p12BetTemp.value += _amount
                    break
                case 'P1_1':
                    p11BetTemp.value += _amount
                    break
                case 'P2_J':
                    p2jBetTemp.value += _amount
                    break
                case 'P2_S':
                    p2sBetTemp.value += _amount
                    break
                case 'P2_2':
                    p22BetTemp.value += _amount
                    break
                case 'P2_1':
                    p21BetTemp.value += _amount
                    break
                case 'P3_J':
                    p3jBetTemp.value += _amount
                    break
                case 'P3_S':
                    p3sBetTemp.value += _amount
                    break
                case 'P3_2':
                    p32BetTemp.value += _amount
                    break
                case 'P3_1':
                    p31BetTemp.value += _amount
                    break
            }

            emitter.emit(EventBuss.PLACE_CHIP, {
                t: _betType,
                amt: _amount,
                type: 'temp',
                deskId: mData.id,
                chip: chip,
                desk: mData
            })

            if (playSoundEffect) audioPlayer.Play(SOUNDKEY.BET_PLACE)

            callUpdateCallback()
        }
    }

    const callUpdateCallback = () => {
        const hasTempBet =
            p1jBetTemp.value +
                p1sBetTemp.value +
                p11BetTemp.value +
                p12BetTemp.value +
                p2jBetTemp.value +
                p2sBetTemp.value +
                p21BetTemp.value +
                p22BetTemp.value +
                p3jBetTemp.value +
                p3sBetTemp.value +
                p31BetTemp.value +
                p32BetTemp.value >
            0

        emit('on-update', hasTempBet)
    }

    const isBetIsSufficient = (
        amount: number,
        betType:
            | 'P1_J'
            | 'P1_S'
            | 'P1_2'
            | 'P1_1'
            | 'P2_J'
            | 'P2_S'
            | 'P2_2'
            | 'P2_1'
            | 'P3_J'
            | 'P3_S'
            | 'P3_2'
            | 'P3_1'
    ) => {
        let multiplier = 1

        if (betType === 'P1_1' || betType === 'P2_1' || betType === 'P3_1')
            multiplier = evenMultiplier.value
        else if (betType === 'P1_2' || betType === 'P2_2' || betType === 'P3_2')
            multiplier = doubleMultiplier.value
        else if (betType === 'P1_S' || betType === 'P2_S' || betType === 'P3_S')
            multiplier = superMultiplier.value

        const tempAmount = amount * multiplier

        // const evenTotalBet =
        //     (p11Bet.value +
        //         p11BetTemp.value +
        //         p21Bet.value +
        //         p21BetTemp.value +
        //         p31Bet.value +
        //         p31BetTemp.value) *
        //     evenMultiplier.value

        // const doubleTotalBet =
        //     (p12Bet.value +
        //         p12BetTemp.value +
        //         p22Bet.value +
        //         p22BetTemp.value +
        //         p32Bet.value +
        //         p32BetTemp.value) *
        //     doubleMultiplier.value
        // const superTotalBet =
        //     (p1sBet.value +
        //         p1sBetTemp.value +
        //         p2sBet.value +
        //         p2sBetTemp.value +
        //         p3sBet.value +
        //         p3sBetTemp.value) *
        //     superMultiplier.value
        // const jackpotTotalBet =
        //     p1jBet.value +
        //     p1jBetTemp.value +
        //     p2jBet.value +
        //     p2jBetTemp.value +
        //     p3jBet.value +
        //     p3jBetTemp.value

        // const total =
        //     tempAmount +
        //     evenTotalBet +
        //     doubleTotalBet +
        //     superTotalBet +
        //     jackpotTotalBet

        const evenTotalBet =
            (p11BetTemp.value + p21BetTemp.value + p31BetTemp.value) *
            evenMultiplier.value

        const doubleTotalBet =
            (p12BetTemp.value + p22BetTemp.value + p32BetTemp.value) *
            doubleMultiplier.value
        const superTotalBet =
            (p1sBetTemp.value + p2sBetTemp.value + p3sBetTemp.value) *
            superMultiplier.value
        const jackpotTotalBet =
            p1jBetTemp.value + p2jBetTemp.value + p3jBetTemp.value

        const total =
            tempAmount +
            evenTotalBet +
            doubleTotalBet +
            superTotalBet +
            jackpotTotalBet

        return coin.value >= total
    }

    const getFillAmount = (
        betType:
            | 'P1_J'
            | 'P1_S'
            | 'P1_2'
            | 'P1_1'
            | 'P2_J'
            | 'P2_S'
            | 'P2_2'
            | 'P2_1'
            | 'P3_J'
            | 'P3_S'
            | 'P3_2'
            | 'P3_1'
    ): number => {
        let multiplier = 1

        if (betType === 'P1_1' || betType === 'P2_1' || betType === 'P3_1')
            multiplier = evenMultiplier.value
        else if (betType === 'P1_2' || betType === 'P2_2' || betType === 'P3_2')
            multiplier = doubleMultiplier.value
        else if (betType === 'P1_S' || betType === 'P2_S' || betType === 'P3_S')
            multiplier = superMultiplier.value

        let result = 0

        const evenTotalBet =
            (p11BetTemp.value + p21BetTemp.value + p31BetTemp.value) *
            evenMultiplier.value
        const doubleTotalBet =
            (p12BetTemp.value + p22BetTemp.value + p32BetTemp.value) *
            doubleMultiplier.value
        const superTotalBet =
            (p1sBetTemp.value + p2sBetTemp.value + p3sBetTemp.value) *
            superMultiplier.value
        const jackpotTotalBet =
            p1jBetTemp.value + p2jBetTemp.value + p3jBetTemp.value

        const total =
            evenTotalBet + doubleTotalBet + superTotalBet + jackpotTotalBet

        result = coin.value - total

        result = result / multiplier

        if (result * multiplier > coin.value) {
            // round it so that it will not eceed the available balance
            result = Math.floor(result * 100) / 100
        }

        if (result < 0) return 0

        return result
    }

    const resetBetUI = (gameType: string, mData?: TableData) => {
        if (mData) {
            const { id } = mData

            let chipId = -1
            if (userChip.value > 0) chipId = userChip.value
            else chipId = chipDefault.value[0].id

            const chipValue = chipDefault.value.find((i) => i.id == chipId)

            // | 'P1_J'
            if (p1jBox.value && p1jBet.value > 0) {
                p1jBox.value.handleOnPlaceChip({
                    t: 'P1_J',
                    amt: p1jBet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }

            // | 'P1_S'
            if (p1sBox.value && p1sBet.value > 0) {
                p1sBox.value.handleOnPlaceChip({
                    t: 'P1_S',
                    amt: p1sBet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }

            // | 'P1_2'
            if (p12Box.value && p12Bet.value > 0) {
                p12Box.value.handleOnPlaceChip({
                    t: 'P1_2',
                    amt: p12Bet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P1_1'
            if (p11Box.value && p11Bet.value > 0) {
                p11Box.value.handleOnPlaceChip({
                    t: 'P1_1',
                    amt: p11Bet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }

            // | 'P2_J'
            if (p2jBox.value && p2jBet.value > 0) {
                p2jBox.value.handleOnPlaceChip({
                    t: 'P2_J',
                    amt: p2jBet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P2_S'
            if (p2sBox.value && p2sBet.value > 0) {
                p2sBox.value.handleOnPlaceChip({
                    t: 'P2_S',
                    amt: p2sBet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P2_2'
            if (p22Box.value && p22Bet.value > 0) {
                p22Box.value.handleOnPlaceChip({
                    t: 'P2_2',
                    amt: p22Bet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P2_1'
            if (p21Box.value && p21Bet.value > 0) {
                p21Box.value.handleOnPlaceChip({
                    t: 'P2_1',
                    amt: p21Bet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }

            // | 'P3_J'
            if (p3jBox.value && p3jBet.value > 0) {
                p3jBox.value.handleOnPlaceChip({
                    t: 'P3_J',
                    amt: p3jBet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P3_S'
            if (p3sBox.value && p3sBet.value > 0) {
                p3sBox.value.handleOnPlaceChip({
                    t: 'P3_S',
                    amt: p3sBet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P3_2'
            if (p32Box.value && p32Bet.value > 0) {
                p32Box.value.handleOnPlaceChip({
                    t: 'P3_2',
                    amt: p32Bet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
            // | 'P3_1',
            if (p31Box.value && p31Bet.value > 0) {
                p31Box.value.handleOnPlaceChip({
                    t: 'P3_1',
                    amt: p31Bet.value,
                    type: 'confirmed',
                    deskId: id,
                    chip: chipValue,
                    desk: mData
                })
            }
        }
    }

    const resetTempBet = () => {
        p1jBetTemp.value = 0
        p1sBetTemp.value = 0
        p11BetTemp.value = 0
        p12BetTemp.value = 0

        p2jBetTemp.value = 0
        p2sBetTemp.value = 0
        p21BetTemp.value = 0
        p22BetTemp.value = 0

        p3jBetTemp.value = 0
        p3sBetTemp.value = 0
        p31BetTemp.value = 0
        p32BetTemp.value = 0

        if (p1jBox.value) p1jBox.value.removeTempUIBet()
        if (p1sBox.value) p1sBox.value.removeTempUIBet()
        if (p11Box.value) p11Box.value.removeTempUIBet()
        if (p12Box.value) p12Box.value.removeTempUIBet()

        if (p2jBox.value) p2jBox.value.removeTempUIBet()
        if (p2sBox.value) p2sBox.value.removeTempUIBet()
        if (p21Box.value) p21Box.value.removeTempUIBet()
        if (p22Box.value) p22Box.value.removeTempUIBet()

        if (p3jBox.value) p3jBox.value.removeTempUIBet()
        if (p3sBox.value) p3sBox.value.removeTempUIBet()
        if (p31Box.value) p31Box.value.removeTempUIBet()
        if (p32Box.value) p32Box.value.removeTempUIBet()

        callUpdateCallback()
    }

    const callSavebetApi = (_params: any) => {
        store.commit(AppMutation.SHOW_LOADING)

        saveBet(_params)
            .then((response) => {
                store.commit(AppMutation.HIDE_LOADING)
                if (response.data.status === 100) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: response.data.description,
                        id: _params.tableId
                    })

                    resetTempBet()
                } else if (response.data.status === 200) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: t('prompt.betsucessfull'),
                        id: _params.tableId
                    })

                    if (lang.value === LANG_CODE.ENGLISH)
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS_EN)
                    else if (lang.value === LANG_CODE.KOREAN)
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS_KOR)
                    else if (lang.value === LANG_CODE.JAPANESE)
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS_JA)
                    else if (lang.value === LANG_CODE.THAI)
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS_TH)
                    else if (lang.value === LANG_CODE.VIETNAMESE)
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS_VN)
                    else audioPlayer.Play(SOUNDKEY.BET_SUCCESS_CN)
                    applyTempBet()
                }
            })
            .catch(() => {
                store.commit(AppMutation.HIDE_LOADING)
                resetTempBet()
                emitter.emit(EventBuss.SMALL_TOAST, {
                    msg: t('prompt.networkerrornetworkerror'),
                    id: _params.tableId
                })
            })
    }

    const applyTempBet = () => {
        p1jBetTemp.value = 0
        p1sBetTemp.value = 0
        p11BetTemp.value = 0
        p12BetTemp.value = 0

        p2jBetTemp.value = 0
        p2sBetTemp.value = 0
        p21BetTemp.value = 0
        p22BetTemp.value = 0

        p3jBetTemp.value = 0
        p3sBetTemp.value = 0
        p31BetTemp.value = 0
        p32BetTemp.value = 0

        if (p1jBox.value) p1jBox.value.applyTempUIBet()
        if (p1sBox.value) p1sBox.value.applyTempUIBet()
        if (p11Box.value) p11Box.value.applyTempUIBet()
        if (p12Box.value) p12Box.value.applyTempUIBet()

        if (p2jBox.value) p2jBox.value.applyTempUIBet()
        if (p2sBox.value) p2sBox.value.applyTempUIBet()
        if (p21Box.value) p21Box.value.applyTempUIBet()
        if (p22Box.value) p22Box.value.applyTempUIBet()

        if (p3jBox.value) p3jBox.value.applyTempUIBet()
        if (p3sBox.value) p3sBox.value.applyTempUIBet()
        if (p31Box.value) p31Box.value.applyTempUIBet()
        if (p32Box.value) p32Box.value.applyTempUIBet()

        callUpdateCallback()
    }

    const endGame = () => {
        if (p1jBox.value) p1jBox.value.resetBetUI()
        if (p1sBox.value) p1sBox.value.resetBetUI()
        if (p11Box.value) p11Box.value.resetBetUI()
        if (p12Box.value) p12Box.value.resetBetUI()

        if (p2jBox.value) p2jBox.value.resetBetUI()
        if (p2sBox.value) p2sBox.value.resetBetUI()
        if (p21Box.value) p21Box.value.resetBetUI()
        if (p22Box.value) p22Box.value.resetBetUI()

        if (p3jBox.value) p3jBox.value.resetBetUI()
        if (p3sBox.value) p3sBox.value.resetBetUI()
        if (p31Box.value) p31Box.value.resetBetUI()
        if (p32Box.value) p32Box.value.resetBetUI()

        resetTempBet()
    }

    const lang = computed((): string => store.getters['lang'])
    const coin = computed(() => store.getters['coin'])
    const userChip = computed(() => store.getters['currentChip'])
    const chipDefault = computed((): ChipItem[] => store.getters['chipDefault'])
    const evenMultiplier = computed(
        (): number => store.getters['evenMultiplier']
    )
    const superMultiplier = computed(
        (): number => store.getters['superMultiplier']
    )
    const doubleMultiplier = computed(
        (): number => store.getters['doubleMultiplier']
    )
    const jackpotBetAmount = computed(
        (): number => store.getters['jackpotBetAmount']
    )

    const p1HasSupperBet = computed(() => p1sBet.value + p1sBetTemp.value > 0)
    const p2HasSupperBet = computed(() => p2sBet.value + p2sBetTemp.value > 0)
    const p3HasSupperBet = computed(() => p3sBet.value + p3sBetTemp.value > 0)

    return {
        // confirmed Bet variables
        p1jBet,
        p1sBet,
        p11Bet,
        p12Bet,

        p2jBet,
        p2sBet,
        p21Bet,
        p22Bet,

        p3jBet,
        p3sBet,
        p31Bet,
        p32Bet,

        // tempBet variables

        p1jBetTemp,
        p1sBetTemp,
        p11BetTemp,
        p12BetTemp,

        p2jBetTemp,
        p2sBetTemp,
        p21BetTemp,
        p22BetTemp,

        p3jBetTemp,
        p3sBetTemp,
        p31BetTemp,
        p32BetTemp,

        p1jBox,
        p1sBox,
        p11Box,
        p12Box,

        p2jBox,
        p2sBox,
        p21Box,
        p22Box,

        p3jBox,
        p3sBox,
        p31Box,
        p32Box,

        p1HasSupperBet,
        p2HasSupperBet,
        p3HasSupperBet,

        placeAbet,
        callSavebetApi,
        endGame,
        resetBetUI,
        resetTempBet
    }
}
