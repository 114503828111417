
import { useBaccaratAndDragontigerBetBox } from '@/composables/useBaccaratAndDragontigerBetBox'
import { EventBuss } from '@/types/global'
import { formatWithCommas } from '@/utils'
import { SvgDolar } from '@/components/icons'
import {
    Emitter,
    PropType,
    computed,
    defineComponent,
    inject,
    onBeforeUnmount,
    onMounted
} from 'vue'

export default defineComponent({
    props: {
        betType: {
            type: String as PropType<
                | 'P1_J'
                | 'P1_S'
                | 'P1_2'
                | 'P1_1'
                | 'P2_J'
                | 'P2_S'
                | 'P2_2'
                | 'P2_1'
                | 'P3_J'
                | 'P3_S'
                | 'P3_2'
                | 'P3_1'
            >,
            required: true
        },
        rect: {
            type: Object as PropType<{
                offsetTop: number
                offsetLeft: number
                w: number
                h: number
            }>,
            required: true
        },
        chipsAttr: {
            type: Object as PropType<{
                width: number
                height: number
                offset: number
            }>,
            default: () => ({ width: 18, height: 12, offset: 5 }),
            required: false
        },
        display: {
            type: String as PropType<'pc' | 'mobile'>,
            required: false,
            default: () => 'pc'
        },
        tempBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        confirmedBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        }
    },
    components: {
        'svg-dolar': SvgDolar
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const {
            chipsUI,
            chipWrapStyle,
            chipStyle,
            handleOnPlaceChip,
            resetBetUI,
            removeTempUIBet,
            applyTempUIBet
        } = useBaccaratAndDragontigerBetBox(
            props.betType,
            props.rect,
            props.chipsAttr,
            props.display
        )

        onMounted(() => {
            emitter.on(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        const formatNumberCurency = (n: number): string => {
            return formatWithCommas(n)
        }

        const amount = computed(() => {
            const { confirmedBet, tempBet } = props

            return confirmedBet + tempBet
        })

        return {
            chipWrapStyle,
            amount,
            chipsUI,
            chipStyle,
            handleOnPlaceChip,
            removeTempUIBet,
            resetBetUI,
            applyTempUIBet,
            formatNumberCurency
        }
    }
})
