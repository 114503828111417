
import { useNiuNiuBetgroup } from '@/composables/useNiuNiuBetgroup'
import { useStore } from '@/store'
import {
    ChipItem,
    PropType,
    TableData,
    computed,
    defineComponent,
    watch
} from 'vue'
import mobileNiuClassicBetBox from './mobile-niu-classic-bet-box.vue'
import { iconUserV1 } from '../icons'
import { Currencies } from '@/types/global'

export default defineComponent({
    emits: ['on-update'],
    props: {
        mData: {
            type: Object as PropType<TableData>,
            require: false
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        },
        winResult: {
            type: Array as PropType<number[]>,
            default: () => []
        }
    },
    components: {
        'bet-box': mobileNiuClassicBetBox,
        'icon-user-v1': iconUserV1
    },
    setup(props, { emit }) {
        const store = useStore()

        const {
            p1jBet,
            p1sBet,
            p11Bet,
            p12Bet,

            p2jBet,
            p2sBet,
            p21Bet,
            p22Bet,

            p3jBet,
            p3sBet,
            p31Bet,
            p32Bet,

            p1jBetTemp,
            p1sBetTemp,
            p11BetTemp,
            p12BetTemp,

            p2jBetTemp,
            p2sBetTemp,
            p21BetTemp,
            p22BetTemp,

            p3jBetTemp,
            p3sBetTemp,
            p31BetTemp,
            p32BetTemp,

            p1jBox,
            p1sBox,
            p11Box,
            p12Box,

            p2jBox,
            p2sBox,
            p21Box,
            p22Box,

            p3jBox,
            p3sBox,
            p31Box,
            p32Box,

            p1HasSupperBet,
            p2HasSupperBet,
            p3HasSupperBet,

            resetBetUI,
            endGame,
            placeAbet,
            callSavebetApi,
            resetTempBet
        } = useNiuNiuBetgroup(emit, true)

        const confirmTempBet = () => {
            if (!props.mData) return

            const { expand, id } = props.mData

            const _params: any = {
                memberId: memberId.value,
                tableId: id,
                shoeNo: expand.shoeNo,
                juNo: expand.juNo,
                gameNo: expand.gameNo,
                token: token.value,
                amount11: p11BetTemp.value,
                amount12: p12BetTemp.value,
                amount13: p1sBetTemp.value,
                amount21: p21BetTemp.value,
                amount22: p22BetTemp.value,
                amount23: p2sBetTemp.value,
                amount31: p31BetTemp.value,
                amount32: p32BetTemp.value,
                amount33: p3sBetTemp.value,
                jackPotOne: p1jBetTemp.value,
                jackPotTwo: p2jBetTemp.value,
                jackPotThree: p3jBetTemp.value
            }

            callSavebetApi(_params)
        }

        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        const box1TotalPlayer = computed(() => {
            if (props.mData) return props.mData.expand.oneNo ?? 0
            return 0
        })

        const box2TotalPlayer = computed(() => {
            if (props.mData) return props.mData.expand.twoNo ?? 0
            return 0
        })

        const box3TotalPlayer = computed(() => {
            if (props.mData) return props.mData.expand.threeNo ?? 0
            return 0
        })

        const box1TotalAmount = computed(() => {
            if (props.mData) return props.mData.expand.oneTotalAmount ?? 0
            return 0
        })

        const box2TotalAmount = computed(() => {
            if (props.mData) return props.mData.expand.twoTotalAmount ?? 0
            return 0
        })

        const box3TotalAmount = computed(() => {
            if (props.mData) return props.mData.expand.threeTotalAmount ?? 0
            return 0
        })

        const currency = computed((): Currencies => store.getters['currency'])

        watch(
            () => props.mData,
            (v) => {
                if (v && v.expand.betFormList) {
                    let _p1sBet = 0,
                        _p11Bet = 0,
                        _p12Bet = 0,
                        _p2sBet = 0,
                        _p21Bet = 0,
                        _p22Bet = 0,
                        _p3sBet = 0,
                        _p31Bet = 0,
                        _p32Bet = 0,
                        _p1jBet = 0,
                        _p2jBet = 0,
                        _p3jBet = 0

                    const { betFormList } = v.expand
                    // p11 = 平倍
                    // p12 = 翻倍
                    // p1s = 多倍
                    betFormList.forEach((i) => {
                        const { amount, playName, pos } = i
                        if (i.memberId === memberId.value) {
                            if (pos === '1') {
                                if (playName === '平倍') _p11Bet += amount
                                else if (playName === '翻倍') _p12Bet += amount
                                else if (playName === '多倍') _p1sBet += amount
                                else if (playName === '彩金') _p1jBet += amount
                            } else if (pos === '2') {
                                if (playName === '平倍') _p21Bet += amount
                                else if (playName === '翻倍') _p22Bet += amount
                                else if (playName === '多倍') _p2sBet += amount
                                else if (playName === '彩金') _p2jBet += amount
                            } else if (pos === '3') {
                                if (playName === '平倍') _p31Bet += amount
                                else if (playName === '翻倍') _p32Bet += amount
                                else if (playName === '多倍') _p3sBet += amount
                                else if (playName === '彩金') _p3jBet += amount
                            }
                        }
                    })

                    p1jBet.value = _p1jBet
                    p1sBet.value = _p1sBet
                    p11Bet.value = _p11Bet
                    p12Bet.value = _p12Bet

                    p2jBet.value = _p2jBet
                    p2sBet.value = _p2sBet
                    p21Bet.value = _p21Bet
                    p22Bet.value = _p22Bet

                    p3jBet.value = _p3jBet
                    p3sBet.value = _p3sBet
                    p31Bet.value = _p31Bet
                    p32Bet.value = _p32Bet
                }
            },
            {
                immediate: true,
                deep: true
            }
        )

        return {
            // confirmed Bet variables
            p1jBet,
            p1sBet,
            p11Bet,
            p12Bet,

            p2jBet,
            p2sBet,
            p21Bet,
            p22Bet,

            p3jBet,
            p3sBet,
            p31Bet,
            p32Bet,

            // tempBet variables

            p1jBetTemp,
            p1sBetTemp,
            p11BetTemp,
            p12BetTemp,

            p2jBetTemp,
            p2sBetTemp,
            p21BetTemp,
            p22BetTemp,

            p3jBetTemp,
            p3sBetTemp,
            p31BetTemp,
            p32BetTemp,

            p1jBox,
            p1sBox,
            p11Box,
            p12Box,

            p2jBox,
            p2sBox,
            p21Box,
            p22Box,

            p3jBox,
            p3sBox,
            p31Box,
            p32Box,

            p1HasSupperBet,
            p2HasSupperBet,
            p3HasSupperBet,

            box1TotalPlayer,
            box2TotalPlayer,
            box3TotalPlayer,

            box1TotalAmount,
            box2TotalAmount,
            box3TotalAmount,
            currency,

            placeAbet,
            resetBetUI,
            resetTempBet,
            confirmTempBet,
            endGame
        }
    }
})
