import { Nui } from '@/types/Nui'
import { TableData, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useNiuCardResult() {
    const { t } = useI18n()

    let isShown = false

    const bankerCards = ref<string[]>([])
    const player1Cards = ref<string[]>([])
    const player2Cards = ref<string[]>([])
    const player3Cards = ref<string[]>([])
    const winResult = ref<Nui>(Nui.Parse(''))
    const player1Flash = ref<number[]>([])
    const player2Flash = ref<number[]>([])
    const player3Flash = ref<number[]>([])
    const bankerFlash = ref<number[]>([])

    const parseResult = (v: TableData) => {
        bankerCards.value = v.expand.bankerOne?.split(',') || []
        player1Cards.value = v.expand.playerOne?.split(',') || []
        player2Cards.value = v.expand.playerTwo?.split(',') || []
        player3Cards.value = v.expand.playerThree?.split(',') || []
        isShown = true
        if (v.expand.whoWin) {
            const _whoWin = String(v.expand.whoWin)
            winResult.value = Nui.Parse(_whoWin, t, [
                bankerCards.value,
                player1Cards.value,
                player2Cards.value,
                player3Cards.value
            ])
            bankerFlash.value = winResult.value.banker.flashIndexs
            player1Flash.value = winResult.value.player1.flashIndexs
            player2Flash.value = winResult.value.player2.flashIndexs
            player3Flash.value = winResult.value.player3.flashIndexs
        }
    }

    return {
        bankerCards,
        player1Cards,
        player2Cards,
        player3Cards,
        winResult,
        isShown,
        bankerFlash,
        player1Flash,
        player2Flash,
        player3Flash,
        parseResult
    }
}
