export function usePrintReport() {
    const printData = (data: any) => {
        let printContent = `<table id="printid">
                <thead>
                    <tr>
                        <th colspan="2">NIUNIU</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>구매시간</td>
                        <td class="right">${data.orderTime}</td>
                    </tr>
                    <tr>
                        <td>테이블번호</td>
                        <td class="right">${data.tableNo}번테이블</td>
                    </tr>
                    <tr>
                        <td>구매번호</td>
                        <td class="right">${data.orderNo}</td>
                    </tr>
                    <tr>
                        <td>게임ID</td>
                        <td class="right">${data.gameNo}</td>
                    </tr>
                    <tr>
                        <td>회원아이디</td>
                        <td class="bold right">${data.memberCode}</td>
                    </tr>
                    <tr>
                      <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="center" colspan="2">발권내역</td>
                    </tr>
                    <tr>
                        <td  class="center">종류</td>
                        <td  class="center">금액</td>
                    </tr>  
                    `

        if (data.playOneSuper || data.playOneDouble || data.playOneEven)
            printContent += `
                    <tr>
                        <td colspan="2" class="center">P1</td>
                    </tr>
                    <tr>
                        <td>SUPER</td>
                        <td class="right">${data.playOneSuper ?? 0}</td>
                    </tr>
                    <tr>
                        <td>DOUBLE</td>
                        <td class="right">${data.playOneDouble ?? 0}</td>
                    </tr>
                    <tr>
                        <td >EVEN</td>
                        <td class="right">${data.playOneEven ?? 0}</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>`
        if (data.playTwoSuper || data.playTwoEven || data.playTwoDouble)
            printContent += `
                    <tr>
                        <td colspan="2" class="center"><div>P2</div></td>
                    </tr>
                    <tr>
                        <td>SUPER</td>
                        <td class="right">${data.playTwoSuper ?? 0}</td>
                    </tr>
                    <tr>
                        <td>DOUBLE</td>
                        <td class="right">${data.playTwoDouble ?? 0}</td>
                    </tr>
                    <tr>
                        <td>EVEN</td>
                        <td class="right">${data.playTwoEven ?? 0}</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>`
        if (data.playThreeSuper || data.playThreeDouble || data.playThreeEven)
            printContent += `
                    <tr>
                        <td colspan="2" class="center"cleaeee><div>P3</div></td>
                    </tr>
                    <tr>
                        <td>SUPER</td>
                        <td class="right">${data.playThreeSuper ?? 0}</td>
                    </tr>
                    <tr>
                        <td>DOUBLE</td>
                        <td class="right">${data.playThreeDouble ?? 0}</td>
                    </tr>
                    <tr>
                        <td>EVEN</td>
                        <td class="right">${data.playThreeEven ?? 0}</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>`

        printContent += `
                    <tr>
                        <td>배팅금액</td>
                        <td class="right">${data.betTotalAmount ?? 0}</td>
                    </tr>
                    <tr>
                        <td>보증금</td>
                        <td class="right">${
                            data.securityDepositTotalAmount ?? 0
                        }</td>
                    </tr>
                    <tr>
                        <td>총</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="2" class="right">DOUBLE 보증금(5배)</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="right">SUPER 보증금(12배)</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                </tbody>
            </table> `

        const printWindow = window.open('', '', 'height=500,width=800') as any

        printWindow.document.write('<html><head><title>Print NIUNIU</title>')
        printWindow.document.write(
            `<style>
            @page{
                margin: 0;
                padding:0;
            }
            @media print { 
                    body * { 
                        visibility: hidden;
                        margin:0;
                        padding:0;
                    } 
                    #printid, 
                    #printid * { visibility: visible; } 
                    #printid { width:210px; border:solid 1px #000;border-collapse:collapse; position: absolute; left: 0; top: 0; width: 100%; margin: 0; padding: 0; font-size: 10px; page-break-after: avoid;}
                    #printid td,#printid th { border:solid 1px #000; padding:5px;}
                    #printid th.center,
                    #printid td.center {text-align: center;}
                    #printid td.right {text-align: right;}
                    #printid td.bold {font-weight:bold;}

                }
            </style>`
        )

        printWindow.document.write('</head><body>')

        printWindow.document.write(printContent)
        printWindow.document.write('</body></html>')

        printWindow.document.close()

        printWindow.print()
    }

    return {
        printData
    }
}
