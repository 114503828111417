
import {
    BonusContent,
    computed,
    defineComponent,
    onMounted,
    PropType
} from 'vue'

export default defineComponent({
    props: {
        bonusInfo: {
            type: Object as PropType<BonusContent>,
            default: () => undefined
        }
    },
    setup(props) {
        const message = computed((): string => {
            if (props.bonusInfo) return props.bonusInfo.message
            return ''
        })

        onMounted(() => {
            //
        })

        const won = computed((): boolean => {
            if (props.bonusInfo) return props.bonusInfo.won
            return false
        })

        return {
            message,
            won
        }
    }
})
